
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import TooltipStatus from "@/components/TooltipStatus.vue";
import BookRate from "@/components/BookRate.vue";
import BookNoteArea from "@/components/BookNoteArea.vue";
import { ErrorMessage } from "@/components/lib";
import { useMachine } from "@xstate/vue";
import { bookMachine } from "@/machines/bookMachine";

export default defineComponent({
  setup() {
    const route = useRoute();
    const bookId = computed(() => route.params.bookId as string);

    const { state: bookState, service: bookService } = useMachine(
      bookMachine({ bookId: bookId.value })
    );
    const isLoading = computed(() =>
      ["loadBook.book", "loadBook.listItem"].some((s) =>
        bookState.value.matches(s as "loadBook.book" | "loadBook.listItem")
      )
    );
    const isError = computed(() => bookState.value.matches("loadBook.failure"));
    const isSuccess = computed(() => bookState.value.matches("success"));
    const error = computed(() => bookState.value.context.message);
    const listItem = computed(() => bookState.value.context?.listItem);
    const isRead = computed(() => bookState.value.matches("success.read"));
    const note = computed(() => bookState.value.context?.note);
    const star = computed(() => bookState.value.context?.star);
    const buttons = computed(() => bookState.value.context?.buttons);
    const book = computed(() => bookState.value.context?.book);

    function formatDate(isostring: number): string {
      return Intl.DateTimeFormat(undefined, {
        month: "short",
        year: "2-digit",
      }).format(new Date(isostring));
    }

    return {
      bookState,
      book,
      bookService,
      isSuccess,
      isLoading,
      listItem,
      formatDate,
      isError,
      error,
      isRead,
      note,
      star,
      buttons,
    };
  },
  components: {
    TooltipStatus,
    BookRate,
    BookNoteArea,
    ErrorMessage,
  },
});
