
import { computed, defineComponent, PropType } from "vue";
import { useActor } from "@xstate/vue";
import { DataMachineActor } from "@/machines/dataMachine";
import type { item } from "@/types";

export default defineComponent({
  props: {
    starRef: {
      type: Object as PropType<DataMachineActor>,
      required: true,
    },
    listItem: {
      type: Object as PropType<item>,
      required: true,
    },
  },
  setup(props) {
    const numberOfStar = 5;
    const { state: starState, send: sendStar } = useActor(props.starRef);
    const isRating = computed(() =>
      props.listItem.rating == -1 ? false : true
    );
    const { isError, error } = {
      isError: computed(() => starState.value.matches("failure")),
      error: computed(() => starState.value.context?.message),
    };
    const rateBook = (data: { id: string; rating: number }) =>
      sendStar({ type: "CLICK", data });

    return { numberOfStar, isRating, rateBook, isError, error };
  },
});
